import { useState, useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import Modal from "react-bootstrap/Modal";
import Badge from "react-bootstrap/Badge";
import axios from "axios";
import swal from "sweetalert";
import { MDBDataTableV5 } from "mdbreact";
import ReactExport from "react-export-excel-xlsx-fix";
import { useDispatch, useSelector } from "react-redux";
import accessAction from "../../actions/accessAction";
import { NavbarComponent } from "../layout/header";
import { GetEvents } from "../../api/getEvents";
import { CSVLink } from "react-csv";
import { GetDataProfitCenter } from "../../api/getProfitCenter";
import moment from "moment";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

export const TableSeminar = (props) => {
  const user = useSelector((value) => value.user);
  const dispatch = useDispatch();
  const years = new Date().getFullYear();
  const [dataAll, setdataAll] = useState([]);
  const [modalImport, setmodalImport] = useState(false);
  const [fileUpload, setfileUpload] = useState();
  const [fileName, setFileName] = useState("");
  const [extention, setExtention] = useState("");
  const [modalApprove, setmodalApprove] = useState(false);
  const [modalApproved, setmodalApproved] = useState(false);
  const [modalNot, setmodalNot] = useState(false);
  const [detailNama, setdetailNama] = useState("");
  const [detailEmail, setdetailEmail] = useState("");
  const [detailNoHp, setdetailNoHp] = useState("");
  const [detailInvoice, setdetailInvoice] = useState("");
  const [detailID, setdetailID] = useState("");
  const [statusApprove, setstatusApprove] = useState(false);
  const [selectEvent, setselectEvent] = useState(0);
  const [eventId, seteventId] = useState(0);
  const [ProfitCenter, setProfitCenter] = useState([]);
  const [newEvent, setnewEvent] = useState("");
  const [startData, setstartData] = useState("");
  const [endData, setendData] = useState("");
  const [description, setdescription] = useState("");
  const [jenisAcara, setjenisAcara] = useState("");
  const [openNew, setopenNew] = useState(false);
  const [formState, setformState] = useState({
    id: 0,
    status: 0,
    idForm: "",
    email: "",
    nama: "",
    whatsapp: "",
  });
  const [listEvent, setlistEvent] = useState([]);

  const [datatable, setDatatable] = useState({
    columns: [
      {
        label: "No",
        field: "no",
        attributes: {
          "aria-controls": "DataTable",
          "aria-label": "No",
        },
      },
      {
        label: "Invoice",
        field: "invoice",
      },
      {
        label: "Nama",
        field: "nama",
      },
      {
        label: "Email",
        field: "email",
      },
      {
        label: "No Telepon",
        field: "notelepon",
      },
      {
        label: "Status",
        field: "status",
      },
    ],
    rows: [],
  });

  const pushDataTable = (array) => {
    const rows = [];
    for (let i = 0; i < array.length; i++) {
      const add = new Object();
      add.no = i + 1;
      add.nama = array[i].nama;
      add.email = array[i].email;
      add.notelepon = array[i].no_tlp;
      add.invoice = array[i].invoice;
      if (array[i].status === 0) {
        add.status = (
          <>
            <Badge
              style={{ width: 80, cursor: "pointer", marginLeft: 5, marginRight: 5 }}
              bg="warning"
              onClick={(e) => {
                detailPeserta(array[i].nama, array[i].email, array[i].no_tlp, array[i].id, array[i].invoice);
              }}
            >
              Approve
            </Badge>
            <Badge
              style={{ width: 80, cursor: "pointer", marginLeft: 5, marginRight: 5 }}
              bg="primary"
              onClick={(e) => {
                DetailPeserta(array[i].id);
              }}
            >
              Update
            </Badge>
            <Badge
              style={{ width: 80, cursor: "pointer", marginLeft: 5, marginRight: 5 }}
              bg="danger"
              onClick={(e) => {
                DeletePeserta(array[i].id);
              }}
            >
              Delete
            </Badge>
          </>
        );
      } else if (array[i].status === 1) {
        add.status = (
          <Badge
            style={{ width: 80, cursor: "pointer" }}
            bg="success"
            onClick={(e) => {
              detailPesertaAproved(array[i].nama, array[i].email, array[i].no_tlp, array[i].id, array[i].invoice);
            }}
          >
            Approved
          </Badge>
        );
      } else {
        add.status = (
          <>
            <Badge
              style={{ width: 80, cursor: "pointer", marginLeft: 5, marginRight: 5 }}
              bg="danger"
              onClick={(e) => {
                detailPesertaNot(array[i].nama, array[i].email, array[i].no_tlp, array[i].id, array[i].invoice);
              }}
            >
              Not attend
            </Badge>
            <Badge
              style={{ width: 80, cursor: "pointer", marginLeft: 5, marginRight: 5 }}
              bg="primary"
              onClick={(e) => {
                DetailPeserta(array[i].id);
              }}
            >
              Update
            </Badge>
            <Badge
              style={{ width: 80, cursor: "pointer", marginLeft: 5, marginRight: 5 }}
              bg="danger"
              onClick={(e) => {
                DeletePeserta(array[i].id);
              }}
            >
              Delete
            </Badge>
          </>
        );
      }
      rows.push(add);
    }
    setDatatable({ ...datatable, rows: rows });
  };

  const allFunctionReload = () => {
    GetDataSeminar(selectEvent);
    getDataGoogleForm();
    const event = GetEvents(user.profit_center_id);
    event.then((res) => {
      setlistEvent(res);
    });

    const profit_center = GetDataProfitCenter();
    profit_center.then((res) => setProfitCenter(res));
  };

  useEffect(() => {
    if (user && user.username) {
      allFunctionReload();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  //export data to excel

  const getDataGoogleForm = () => {
    axios
      .get("/data-form")
      .then((res) => {
        if (res.data.data.length > 0) {
          const FormData = res.data.data[0];
          setformState({
            id: FormData.id,
            status: FormData.status,
            idForm: FormData.idForm,
            email: FormData.email,
            nama: FormData.nama,
            whatsapp: FormData.whatsapp,
          });
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //delete all data
  const DeleteAll = (id) => {
    axios
      .delete("/delete-all/"+id)
      .then((res) => {
        setdataAll(res.data.data);
        pushDataTable(res.data.data);
        swal({
          title: "Delete Success",
          text: "Click the button to exit!",
          icon: "success",
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //aprove data
  const ApproveFunc = (id, type, nama, email, whatsapp) => {
    axios
      .post("/approve", { id, type })
      .then((res) => {
        setdataAll(res.data.data);
        pushDataTable(res.data.data);
        if (formState.id !== 0 && formState.status !== 0) {
          const linkSertifikat = `https://docs.google.com/forms/d/e/${formState.idForm}/formResponse?usp=pp_url&${formState.email}=${email}&${formState.nama}=${nama}&${formState.whatsapp}=${whatsapp}&submit=Submit`;
          // setmodalApprove(false)
          if (type === 1) {
            window.open(linkSertifikat, "_blank");
            setstatusApprove(true);
            swal({
              title: "Success",
              text: "",
              icon: "success",
            });
          } else if (type === 0) {
            setmodalApprove(false);
            setmodalApproved(false);
            setmodalNot(false);
            swal({
              title: "Reset Data",
              text: "Click the button to exit!",
              icon: "warning",
            });
          } else {
            setmodalApprove(false);
            setmodalApproved(false);
            setmodalNot(false);
            swal({
              title: "Not Attend",
              text: "Click the button to exit!",
              icon: "warning",
            });
          }
        } else {
          if (type === 1) {
            setstatusApprove(true);
            swal({
              title: "Success",
              text: "",
              icon: "success",
            });
          } else if (type === 0) {
            setmodalApprove(false);
            setmodalApproved(false);
            setmodalNot(false);
            swal({
              title: "Reset Data",
              text: "Click the button to exit!",
              icon: "warning",
            });
          } else {
            setmodalApprove(false);
            setmodalApproved(false);
            setmodalNot(false);
            swal({
              title: "Not Attend",
              text: "Click the button to exit!",
              icon: "warning",
            });
          }
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  //modal detail aprove
  const detailPeserta = (nama, email, nohp, id, invoice) => {
    setdetailID(id);
    setdetailNama(nama);
    setdetailEmail(email);
    setdetailNoHp(nohp);
    setdetailInvoice(invoice);
    setmodalApprove(true);
  };

  //modal detail aproved
  const detailPesertaAproved = (nama, email, nohp, id, invoice) => {
    setdetailID(id);
    setdetailNama(nama);
    setdetailEmail(email);
    setdetailNoHp(nohp);
    setdetailInvoice(invoice);
    setmodalApproved(true);
  };

  //modal detail Not
  const detailPesertaNot = (nama, email, nohp, id, invoice) => {
    setdetailID(id);
    setdetailNama(nama);
    setdetailEmail(email);
    setdetailNoHp(nohp);
    setdetailInvoice(invoice);
    setmodalNot(true);
  };

  //change value
  const handleInputChange = (event) => {
    var re = /(?:\.([^.]+))?$/;
    let name = event.target.files[0].name;
    setfileUpload(event.target.files[0]);
    setFileName(name);
    setExtention(re.exec(name)[1]);
  };

  //submit import data
  const submit = async (newEvent, idEvent, startData, endData, descriptionm, jenis_acara) => {
    if (idEvent === "0") {
      swal({
        title: "Please fill the select the event",
        text: "Click the button to exit!",
        icon: "warning",
      });
    } else {
      // if newEvent
      if (newEvent === "") {
        swal({
          title: "Please fill the event name",
          text: "Click the button to exit!",
          icon: "warning",
        });
      } else {
        if (fileUpload !== null) {
          if (extention === "xlsx") {
            const formData = new FormData();
            formData.append("file", fileUpload);
            formData.append("fileName", fileName);
            formData.append("extention", extention);
            formData.append("new_event", newEvent);
            formData.append("id_event", idEvent);
            formData.append("start_data", startData);
            formData.append("end_data", endData);
            formData.append("description", description);
            formData.append("jenis_acara", jenis_acara);
            try {
              const res = await axios.post("/import-data", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });
              if (res.data.status === 400) {
                swal({
                  title: "Error",
                  text: res.data.message,
                  icon: "error",
                });
              } else {
                allFunctionReload();
                setmodalImport(false);
                swal({
                  title: "Success",
                  text: "Click the button to exit!",
                  icon: "success",
                });
              }
            } catch (err) {
              console.log(err);
            }
          } else {
            swal({
              title: "Error",
              text: "File must be xlsx or xls",
              icon: "error",
            });
          }
        }
      }
    }
  };

  const logOut = () => {
    setdataAll([]);
    pushDataTable([]);
    dispatch(accessAction(false));
    localStorage.removeItem("access_token");
  };

  // donwload template
  const dataTemplate = [
    {
      id: 1,
      invoice: "Y-0001",
      nama: "Astronacci",
      no_tlp: "08123456789",
      email: "email@astronacci.net",
    },
    {
      id: 2,
      invoice: "X-123213",
      nama: "Astronacci",
      no_tlp: "123456789",
      email: "email@gmail.com",
    },
  ];

  // get data seminar with params
  const GetDataSeminar = (id) => {
    axios.get("/data-seminar-event/" + id).then((res) => {
      pushDataTable(res.data.data);
      setdataAll(res.data.data);
    });
  };

  let csvData = [["NO; INVOICE ; NAMA ; EMAIL ; TELP ; ATTENDANCE"]];

  dataAll.map((item, index) => {
    const status = item.status === 1 ? "Hadir" : "Tidak Hadir";
    csvData.push([
      index + 1 + ";" + item.invoice + ";" + item.nama + ";" + item.email + ";" + item.no_tlp + ";" + status,
    ]);
  });

  //state modal add
  const [modalAdd, setmodalAdd] = useState(false);

  const [formData, setformData] = useState({
    id: "",
    nama: "",
    email: "",
    no_tlp: "",
    invoice: "",
    event_id: selectEvent,
  });

  const AddPesertaFunc = () => {
    if (
      formData.email === "" ||
      formData.nama === "" ||
      formData.no_tlp === "" ||
      formData.invoice === "" ||
      formData.event_id === 0
    ) {
      swal({
        title: "Please fill the form",
        text: "Click the button to exit!",
        icon: "warning",
      });
    } else {
      if (
        formData.email
          .toLowerCase()
          .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
          )
      ) {
        axios.post("/create-peserta-seminar", formData).then((res) => {
          if (res.data.status === 400) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
            });
          } else {
            allFunctionReload();
            setformData({
              nama: "",
              email: "",
              no_tlp: "",
              invoice: "",
              event_id: selectEvent,
            });
            setmodalAdd(false);
            swal({
              title: "Success",
              text: "Click the button to exit!",
              icon: "success",
            });
          }
        });
      } else {
        swal({
          title: "Error",
          text: "Email must be include @",
          icon: "error",
        });
      }
    }
  };

  const DeletePeserta = (id) => {
    swal({
      title: "Are you sure?",
      text: "Once deleted, you will not be able to recover this data!",
      icon: "warning",
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        axios.get("/delete-peserta-seminar/" + id).then((res) => {
          if (res.data.status === 400) {
            swal({
              title: "Error",
              text: res.data.message,
              icon: "error",
            });
          } else {
            allFunctionReload();
            swal({
              title: "Success",
              text: "Click the button to exit!",
              icon: "success",
            });
          }
        });
      } else {
        swal("Your data is safe!");
      }
    });
  };

  const updatePeserta = () => {
    if (
      formData.email === "" ||
      formData.nama === "" ||
      formData.no_tlp === "" ||
      formData.invoice === "" ||
      formData.event_id === 0
    ) {
      swal({
        title: "Please fill the form",
        text: "Click the button to exit!",
        icon: "warning",
      });
    }
    if (
      formData.email
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        )
    ) {
      axios.post("/update-peserta-seminar", formData).then((res) => {
        if (res.data.status === 400) {
          swal({
            title: "Error",
            text: res.data.message,
            icon: "error",
          });
        } else {
          allFunctionReload();
          setformData({
            nama: "",
            email: "",
            no_tlp: "",
            invoice: "",
            event_id: selectEvent,
          });
          setmodalAdd(false);
          swal({
            title: "Success",
            text: "Click the button to exit!",
            icon: "success",
          });
        }
      });
    }
  };

  const DetailPeserta = (id) => {
    axios.get("/detail-peserta-seminar/" + id).then((res) => {
      if (res.data.status === 400) {
        swal({
          title: "Error",
          text: res.data.message,
          icon: "error",
        });
      } else {
        setformData(res.data.data);
        setmodalAdd(true);
      }
    });
  };

  const SubmitToDatabase = async (id) => {
    const result = await axios
      .get("/event/member/" + id)
      .then((res) => {
        return res.data;
      })
      .catch((err) => {
        return err.response.data;
      });

    swal({
      title: "Success",
      text: result.message,
      icon: "success",
    });
  };

  return (
    <>
      <NavbarComponent logOut={logOut} user={user.display_name} />
      <Container>
        <div style={{ padding: 10 }} className="mb-5">
          <ExcelFile
            filename={"Data Template Import Seminar " + years}
            element={
              <Button className="me-1 ms-1 mt-2" variant="warning">
                <i className="bi bi-download"></i> Download Template Import
              </Button>
            }
          >
            <ExcelSheet data={dataTemplate} name={"Data-Seminar " + years}>
              <ExcelColumn label="No" value="id" />
              <ExcelColumn label="Invoice" value="invoice" />
              <ExcelColumn label="Nama" value="nama" />
              <ExcelColumn label="Email" value="email" />
              <ExcelColumn label="No Hp" value="no_tlp" />
            </ExcelSheet>
          </ExcelFile>
          <Button
            className="me-1 ms-1 mt-2"
            onClick={(e) => {
              setmodalImport(true);
            }}
            variant="primary"
          >
            <i className="bi bi-upload"></i> Import Data
          </Button>
          {dataAll.length > 0 ? (
            <>
              <ExcelFile
                filename={"Data Seminar " + fileName}
                element={
                  <Button className="me-1 ms-1 mt-2" variant="secondary">
                    <i className="bi bi-download"></i> Export Data EXCEL
                  </Button>
                }
              >
                <ExcelSheet data={dataAll} name={"Data-Seminar-" + years}>
                  <ExcelColumn label="NO" value="id" />
                  <ExcelColumn label="INVOICE" value="invoice" />
                  <ExcelColumn label="NAMA" value="nama" />
                  <ExcelColumn label="EMAIL" value="email" />
                  <ExcelColumn label="TELP" value="no_tlp" />
                  <ExcelColumn label="ATTENDANCE" value={(col) => (col.status === 1 ? "Hadir" : "Tidak Hadir")} />
                </ExcelSheet>
              </ExcelFile>

              <CSVLink data={csvData} target="_blank" filename={"Data Seminar " + fileName + ".csv"}>
                <Button className="me-1 ms-1 mt-2" variant="success">
                  <i className="bi bi-download"></i> Export Data CSV
                </Button>
              </CSVLink>
              <Button
                className="me-1 ms-1 mt-2"
                variant="danger"
                onClick={() => {
                  SubmitToDatabase(selectEvent);
                }}
              >
                <i className="bi bi-download"></i> Sync To Database
              </Button>
            </>
          ) : (
            <>
              <Button
                className="me-1 ms-1 mt-2"
                onClick={(e) => {
                  swal({
                    title: "No Data to Export",
                    text: "Please import data first!",
                    icon: "warning",
                  });
                }}
                variant="secondary"
              >
                <i className="bi bi-download"></i> Export Data Excel
              </Button>
              <Button
                className="me-1 ms-1 mt-2"
                onClick={(e) => {
                  swal({
                    title: "No Data to Export",
                    text: "Please select event first!",
                    icon: "warning",
                  });
                }}
                variant="success"
              >
                <i className="bi bi-download"></i> Export Data Csv
              </Button>

              <Button
                className="me-1 ms-1 mt-2"
                variant="danger"
                onClick={() => {
                  swal({
                    title: "No Data to Selected",
                    text: "Please select event first!",
                    icon: "warning",
                  });
                }}
              >
                <i className="bi bi-download"></i> Sync To Database
              </Button>
            </>
          )}
        </div>
        <div className="d-flex justify-content-end">
          <div className="w-50">
            <select
              className="form-control"
              onChange={(e) => {
                setformData({ ...formData, event_id: parseInt(e.target.value.split("&&")[0]) });
                setselectEvent(parseInt(e.target.value.split("&&")[0]));
                setFileName(e.target.value.split("&&")[1]);
                GetDataSeminar(parseInt(e.target.value.split("&&")[0]));
              }}
            >
              <option value="0">Pilih Event</option>
              {listEvent.length > 0 &&
                listEvent.map((item, index) => (
                  <option key={index} value={item.id + "&&" + item.name}>
                    {item.name}
                    {item.event_date_from || item.event_date_to ? (
                      <>
                        &nbsp; - ( {moment(item.event_date_from).format("DD MMMM YYYY")} -{" "}
                        {moment(item.event_date_to).format("DD MMMM YYYY")} )
                      </>
                    ) : (
                      <></>
                    )}
                  </option>
                ))}
            </select>
          </div>
          <button className="btn btn-primary ms-2 ms-2" onClick={() => GetDataSeminar(selectEvent)}>
            <i className="bi bi-search"></i>
          </button>
          {/* <button className="btn btn-danger ms-1 ms-1" onClick={() => {
            if(selectEvent === 0){
              swal({
                title: "Please fill the event",
                text: "Click the button to exit!",
                icon: "warning",
              });
              return
            }

            if(dataAll.length === 0){
              swal({
                title: "No Data to Delete",
                text: "Please import data first!",
                icon: "warning",
              });
              return
            }

            DeleteAll(selectEvent)
          }}>
            <i className="bi bi-trash"></i>
            <span className="ms-2">Delete All</span>
          </button> */}
        </div>

        <div className="d-flex justify-content-end">
          <div className="mt-5">
            <button
              className="btn btn-primary"
              onClick={() => {
                if (selectEvent === 0) {
                  swal({
                    title: "Pilih Event Terlebih Dahulu",
                    text: "Click the button to exit!",
                    icon: "warning",
                  });
                } else {
                  setmodalAdd(true);
                }
              }}
            >
              <i className="bi bi-plus"></i>Add Peserta
            </button>
          </div>
        </div>
        <MDBDataTableV5
          striped
          responsive
          hover
          entriesOptions={[5, 10, 20, 25]}
          entries={10}
          pagesAmount={4}
          data={datatable}
          searchTop
          searchBottom={false}
        />
        <Modal
          show={modalImport}
          onHide={() => setmodalImport(false)}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">IMPORT DATA SEMINAR</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="form-group mb-2">
              <label>Event <span style={{color: "red"}}>*</span></label>
              <select
                className="form-control"
                onChange={(e) => {
                  seteventId(e.target.value.split("&&")[0]);
                  setnewEvent(e.target.value.split("&&")[1]);
                  if (e.target.value === "-1") {
                    setopenNew(true);
                  } else {
                    setopenNew(false);
                  }
                }}
              >
                <option value="0">-- Select Event --</option>
                <option value="-1">-- Tambah Event Baru --</option>
                {listEvent.length > 0 &&
                  listEvent.map((item, index) => (
                    <option key={index} value={item.id + "&&" + item.name}>
                      {item.name}
                      {item.event_date_from || item.event_date_to ? (
                        <>
                          &nbsp; - ( {moment(item.event_date_from).format("DD MMMM YYYY")} -{" "}
                          {moment(item.event_date_to).format("DD MMMM YYYY")} )
                        </>
                      ) : (
                        <></>
                      )}
                    </option>
                  ))}
              </select>
            </div>
            {openNew && (
              <>
                <div className="form-group mb-2">
                  <label>Add New Event <span style={{color: "red"}}>*</span></label>
                  <input
                    type="text"
                    className="form-control"
                    value={newEvent}
                    onChange={(e) => {
                      setnewEvent(e.target.value);
                    }}
                    placeholder="Event Name"
                  />
                </div>
                <div className="form-group mb-2">
                  <label>Start Date <span style={{color: "red"}}>*</span></label>
                  <input
                    type="date"
                    className="form-control"
                    value={startData}
                    onChange={(e) => {
                      setstartData(e.target.value);
                    }}
                  />
                </div>
                <div className="form-group mb-2">
                  <label>End Date <span style={{color: "red"}}>*</span></label>
                  <input
                    type="date"
                    className="form-control"
                    value={endData}
                    onChange={(e) => {
                      setendData(e.target.value);
                    }}
                  />
                </div>
                {/* <div className="form-group mb-2">
                  <label>Description</label>
                  <input
                    type="text"
                    className="form-control"
                    value={description}
                    onChange={(e) => {
                      setdescription(e.target.value);
                    }}
                    placeholder="Description"
                  />
                </div>
                <div className="form-group mb-2">
                  <label>Jenis Acara</label>
                  <input
                    type="text"
                    className="form-control"
                    value={jenisAcara}
                    onChange={(e) => {
                      setjenisAcara(e.target.value);
                    }}
                    placeholder="Jenis Acara"
                  />
                </div> */}
              </>
            )}
            <div className="form-group">
              <label>File Excel <span style={{color: "red"}}>*</span></label>
              <input
                type="file"
                onChange={handleInputChange}
                accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                className="form-control"
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            <label style={{ color: "" }}>{}</label>
            <Button
              variant="primary"
              size="lg"
              onClick={() => {
                if(eventId === "0" || eventId === 0){
                  swal({
                    title: "Please fill the event",
                    text: "Click the button to exit!",
                    icon: "warning",
                  });
                  return
                } else if(eventId === "-1"){
                  if(newEvent === undefined){
                    swal({
                      title: "Please fill the event name",
                      text: "Click the button to exit!",
                      icon: "warning",
                    });
                    return
                  }

                  if(startData === ""){
                    swal({
                      title: "Please fill the start date",
                      text: "Click the button to exit!",
                      icon: "warning",
                    });
                    return
                  }

                  if(endData === ""){
                    swal({
                      title: "Please fill the end date",
                      text: "Click the button to exit!",
                      icon: "warning",
                    });

                    return
                  }
                }

              
                if(fileUpload === undefined){
                    swal({
                      title: "Please fill the file",
                      text: "Click the button to exit!",
                      icon: "warning",
                    });
                    return
                  }

                submit(newEvent, eventId, startData, endData, description, jenisAcara)
              }}
            >
              SAVE
            </Button>
          </Modal.Footer>
        </Modal>

        <Modal
          show={modalApprove}
          onHide={() => {
            setmodalApprove(false);
            setTimeout(() => {
              setstatusApprove(false);
            }, 500);
          }}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">DETAIL</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <div className="form-group">
                <label>Invoice : </label>
                <label>&nbsp; {detailInvoice}</label>
              </div>
              <div className="form-group">
                <label>Nama : </label>
                <label>&nbsp; {detailNama}</label>
              </div>
              <div className="form-group">
                <label>Email : </label>
                <label>&nbsp; {detailEmail}</label>
              </div>
              <div className="form-group">
                <label>No Hp : </label>
                <label>&nbsp; {detailNoHp}</label>
              </div>
              {statusApprove ? (
                <div className="form-group">
                  <Badge style={{ width: 80 }} bg="success">
                    Approved
                  </Badge>
                </div>
              ) : null}
            </div>
          </Modal.Body>
          {statusApprove ? null : (
            <>
              <Modal.Footer>
                <Button
                  variant="success"
                  size="lg"
                  onClick={(e) => {
                    ApproveFunc(detailID, 1, detailNama, detailEmail, detailNoHp);
                  }}
                >
                  Approve
                </Button>
                <Button
                  variant="danger"
                  size="lg"
                  onClick={(e) => {
                    ApproveFunc(detailID, -1, detailNama, detailEmail, detailNoHp);
                  }}
                >
                  Not Attend
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>

        <Modal
          show={modalApproved}
          onHide={() => {
            setmodalApproved(false);
            setTimeout(() => {
              setstatusApprove(false);
            }, 500);
          }}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">DETAIL</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <div className="form-group">
                <label>Invoice : </label>
                <label>&nbsp; {detailInvoice}</label>
              </div>
              <div className="form-group">
                <label>Nama : </label>
                <label>&nbsp; {detailNama}</label>
              </div>
              <div className="form-group">
                <label>Email : </label>
                <label>&nbsp; {detailEmail}</label>
              </div>
              <div className="form-group">
                <label>No Hp : </label>
                <label>&nbsp; {detailNoHp}</label>
              </div>
              {statusApprove ? (
                <div className="form-group">
                  <Badge style={{ width: 80 }} bg="success">
                    Approved
                  </Badge>
                </div>
              ) : null}
            </div>
          </Modal.Body>
          {statusApprove ? null : (
            <>
              <Modal.Footer>
                <Button
                  variant="warning"
                  size="lg"
                  onClick={(e) => {
                    ApproveFunc(detailID, 0, detailNama, detailEmail, detailNoHp);
                  }}
                >
                  Cancel Approved
                </Button>
                <Button
                  variant="danger"
                  size="lg"
                  onClick={(e) => {
                    ApproveFunc(detailID, -1, detailNama, detailEmail, detailNoHp);
                  }}
                >
                  Not Attend
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>

        <Modal
          show={modalNot}
          onHide={() => {
            setmodalNot(false);
            setTimeout(() => {
              setstatusApprove(false);
            }, 500);
          }}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">DETAIL</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-center">
              <div className="form-group">
                <label>Invoice : </label>
                <label>&nbsp; {detailInvoice}</label>
              </div>
              <div className="form-group">
                <label>Nama : </label>
                <label>&nbsp; {detailNama}</label>
              </div>
              <div className="form-group">
                <label>Email : </label>
                <label>&nbsp; {detailEmail}</label>
              </div>
              <div className="form-group">
                <label>No Hp : </label>
                <label>&nbsp; {detailNoHp}</label>
              </div>
              {statusApprove ? (
                <div className="form-group">
                  <Badge style={{ width: 80 }} bg="success">
                    Approved
                  </Badge>
                </div>
              ) : null}
            </div>
          </Modal.Body>
          {statusApprove ? null : (
            <>
              <Modal.Footer>
                <Button
                  variant="success"
                  size="lg"
                  onClick={(e) => {
                    ApproveFunc(detailID, 1, detailNama, detailEmail, detailNoHp);
                  }}
                >
                  Approved
                </Button>
                <Button
                  variant="warning"
                  size="lg"
                  onClick={(e) => {
                    ApproveFunc(detailID, 0, detailNama, detailEmail, detailNoHp);
                  }}
                >
                  Cancel Not Attend
                </Button>
              </Modal.Footer>
            </>
          )}
        </Modal>

        <Modal
          show={modalAdd}
          onHide={() => {
            setmodalAdd(false);
          }}
          dialogClassName="modal-90w"
          aria-labelledby="example-custom-modal-styling-title"
        >
          <Modal.Header closeButton>
            <Modal.Title id="example-custom-modal-styling-title">
              {formData.id ? "Edit" : "Add"} Data Peserta
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="text-left px-2">
              <div className="form-group mb-2">
                <label className="mb-1">Invoice : </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Invoice"
                  value={formData.invoice}
                  onChange={(e) => {
                    setformData({
                      ...formData,
                      invoice: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group mb-2">
                <label className="mb-1">Nama : </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="Nama"
                  value={formData.nama}
                  onChange={(e) => {
                    setformData({
                      ...formData,
                      nama: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group mb-2">
                <label className="mb-1">Email : </label>
                <input
                  type="email"
                  className="form-control"
                  placeholder="Email"
                  value={formData.email}
                  onChange={(e) => {
                    setformData({
                      ...formData,
                      email: e.target.value,
                    });
                  }}
                />
              </div>
              <div className="form-group mb-2">
                <label className="mb-1">No Hp : </label>
                <input
                  type="text"
                  className="form-control"
                  placeholder="No Hp"
                  value={formData.no_tlp}
                  onChange={(e) => {
                    setformData({
                      ...formData,
                      no_tlp: e.target.value,
                    });
                  }}
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="success"
              size="lg"
              onClick={(e) => {
                if (formData.id) {
                  updatePeserta();
                } else {
                  AddPesertaFunc();
                }
              }}
            >
              {formData.id ? "Update" : "Add"}
            </Button>
          </Modal.Footer>
        </Modal>
      </Container>
    </>
  );
};
