import axios from "axios";

export const GetDataProfitCenter = async () => {
  return await axios
    .get("/get-profit-center")
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return err;
    });
};
