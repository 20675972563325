import { createStore } from "redux";
import accessReducer from "./reducers/accessReducer";

function configureStore(
  state = {
    access: false,
    user: {
      id: null,
      username: null,
      level: null,
      profit_center_id: null,
    },
  }
) {
  return createStore(accessReducer, state);
}

export default configureStore;
