import axios from "axios";

export const GetEvents = async (id) => {
  return await axios
    .post("/get-event", { id: id })
    .then((res) => {
      return res.data.data;
    })
    .catch((err) => {
      return err;
    });
};
