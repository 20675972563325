import logo from "./logo.svg";
import "./App.css";
import "bootstrap-icons/font/bootstrap-icons.css";
import "mdb-react-ui-kit/dist/css/mdb.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import { ComponentLogin } from "./components/login/login";
import axios from "axios";
import { useState, useEffect } from "react";
import { TableSeminar } from "./components/admin/tableSeminar.js";
import { connect } from "react-redux";
import { startAction } from "./actions/startAction";
import { stopAction } from "./actions/stopAction";
import { useSelector, useDispatch } from "react-redux";
import accessAction from "./actions/accessAction";
import userAccess from "./actions/userAction";
import { AdminComponent } from "./components/superadmin/admin";
import { Rating } from "react-simple-star-rating";

const mapStateToProps = (state) => ({
  ...state,
});

const mapDispatchToProps = (dispatch) => ({
  startAction: () => dispatch(startAction),
  stopAction: () => dispatch(stopAction),
});

function App() {
  const access = useSelector((value) => value.access);
  const user = useSelector((value) => value.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (localStorage.getItem("access_token") !== null) {
      axios.defaults.headers.common["Authorization"] = "Bearer " + localStorage.getItem("access_token");
      axios
        .get("/current-user")
        .then((res) => {
          dispatch(accessAction(true));
          dispatch(userAccess(res.data));
        })
        .catch((err) => {
          console.log(err);
        });
    }
  }, [access, dispatch]);

  return (
    <div className="App">
      {access ? user === null ? null : user.level === 0 ? <AdminComponent /> : <TableSeminar /> : <ComponentLogin />}
    </div>
  );
}

export default connect(mapStateToProps, mapDispatchToProps)(App);
